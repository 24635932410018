.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120vh; /* Ensure it takes the full height of the viewport */
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    overflow: auto;
    background: linear-gradient(170deg, rgb(210, 144, 109) 3%, rgb(75, 8, 26) 38%, rgba(48,238,226,1) 68%, rgb(66, 85, 164) 98%);
    animation: gradient 120s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.body-text {
    position: absolute;
    text-align: center; /* Center text within the container */
    left: 0;
    width: 50vw;
}

.front-text {
    color: white;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center buttons horizontally */
    margin-top: 20px; /* Adjust margin as needed */
}

.button-container button {
    background: transparent;
    border: none;
    margin: 0 10px; /* Add some margin between buttons */
}

.button-container button a {
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 20px;
    color: white;
}

.button-container button:hover {
    background-color: rgba(0, 0, 0, 0.2);;
}

.image-container {
    margin-bottom: 20px; /* Adjust as needed */
}

.logo {
    width: 200px; /* Adjust the width of the image as needed */
    height: auto;
}

.title-text {
    font-size: 40px;
    padding: 10px;
}

.animation-box {
    padding: 20px;
    background-color: transparent; /* Light grey background */
    border-radius: 5px;
    position: absolute;
    text-align: center; /* Center text within the container */
    left: 60vw;
    width: auto;
}

.animation {
    width: 30vw; /* Increase the width slightly */
    height: auto; /* Set a specific height to match the width */
}

.animation img {
    width: 100%;
    height: auto;
    border-radius: 15px; /* Adjust the value as needed */
}

.blinking-cursor {}

@keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: #ffffff; /* Same color as the text */
    }
}

.body-page-2 {
    height: 100vh;
    width: 40vw;
    position: absolute;
    content: "";
    background: linear-gradient(
            to bottom,
            rgb(66, 85, 164, 0.6),
            rgba(0, 0, 0, 0.4), /* Middle with less opacity */
            rgba(0, 0, 0, 0.2), /* Lighter opacity */
            rgba(0, 0, 0, 0)    /* Fully transparent at the bottom */
    );
    background-size: cover;
    background-position: center;
    animation: fadeIn 3s forwards; /* Animation for the fading effect */
}

.body-page-2 h1 {
    padding: 20px;
}

.beta-signup {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7); /* Blue background with 70% opacity */
    border-radius: 15px;
    position: absolute;
    text-align: center; /* Center text within the container */
    left: 60vw;
    width: 30vw;
    height: auto;
    max-width: 100%; /* Ensure the container doesn't exceed the viewport width */
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.beta-signup h2 {
    color: white;
    margin-bottom: 20px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%; /* Ensure the form takes up the full width of the container */
    box-sizing: border-box;
}

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.signup-form input,
.signup-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: sans-serif;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    resize: none;
    word-wrap: break-word; /* Ensure text wraps within the input field */
}

.signup-form button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 100px;
    transition: background-color 0.3s ease;
    width: 100%; /* Ensure the button takes up the full width of the container */
    box-sizing: border-box;
}

.beta-message-homepage {
    height: 20vh;
    overflow-y: auto; /* Enable vertical scrolling */
}

.signup-form button:hover {
    background-color: #45a049;
}

/* Import the Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Apply the styles to your h1 element */
.ampari-main-titlename {
    font-size: 52px; /* Adjust font size to 52 points */
    font-family: 'Poppins', sans-serif; /* Apply Poppins font with fallback to sans-serif */
    /* Additional styles if needed */
}
