/* Navbar.css page */


.navbar {
    display: flex;
    align-items: center;
    justify-content: center; /* Add this line */
    background-color: black;

}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    background-color: rgba(0, 0, 0, 0.0); /* Blue background with 70% opacity */
    z-index: 1000; /* Ensure it's above other content */
    padding: 10px 0;
}

.navbar.scrolled {
    position: fixed; /* Fixed position so it overlays other content */
    top: 0; /* Stick to the top of the viewport */
    left: 0;
    width: 100%; /* Full width */
    background-color: rgba(255, 255, 255, 0); /* Fully transparent */
    backdrop-filter: blur(10px); /* Optional: Adds a blur effect to underlying content */
    z-index: 999; /* Ensure it's above other content */
    box-shadow: 0 2px 2px rgb(0, 0, 0, 0.3);

}



ul {
    list-style-type: none;
    margin: 0;
    padding: 10px;
    overflow: hidden;
}

li {
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    border-radius: 25px; /* Adjust the value to make the shape oval */

}


/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: rgba(0, 0, 0, 0.2);;
    border: 1px solid white; /* Thin white border */

}

.logo-container {
    position: absolute;
    left: 10px;
    width: 5vw; /* Increase the width slightly */
    height: 3vw; /* Set a specific height to match the width */
    align-items: center; /* Center items vertically */
}



.logo-container img {
    width: 80%; /* Ensure the image fits the container's width */
    height: auto; /* Maintain the image's aspect ratio */
}



@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

