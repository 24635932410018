body {
    font-family: Arial, sans-serif;
    background-color: #000000;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 4rem;
    max-width: 600px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: black;
}

h1 {
    color: #333;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input, textarea {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

textarea {
    resize: vertical;
}

button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

p {
    color: black;
}

button:hover {
    background-color: #0056b3;
}

.saved-endpoint {
    margin-top: 2rem;
    width: 100%;

}

.saved-endpoint h2 {
    margin-bottom: 1rem;
    color: black;
}

.saved-endpoint p {
    margin-bottom: 0.5rem;
    color: black;
}

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: -ms-repeating-linear-gradient(135deg,
    black,
    blue); /* Rainbow gradient background */    color: #fff;
    font-family: Arial, sans-serif;
}