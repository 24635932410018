/* popup.css */


.register-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
}

.register-card {
    width: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.register-card .card-body {
    padding: 40px;
    background: #ffffff;
    border-radius: 10px;
}

.register-card .card-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

/* Form styles */
.form-group {
    margin-bottom: 20px;
}

.form-label {
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.form-control {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}

.form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

/* Button styles */
.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

/* Error and Success messages */
.error-message,
.success-message {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
}

.error-message {
    color: #d9534f;
}

.success-message {
    color: #5cb85c;
}