/* Container for the chat application */
.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 600px; /* Increased height */
    width: 100%;
    max-width: 500px; /* Increased width */
    border: 1px solid #ccc;
    border-radius: 12px; /* Increased border radius */
    overflow: hidden;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #f5f7fa; /* Subtle background color */
}

/* Container for chat messages */
.chat-messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px; /* Increased padding */
    background-color: #ffffff;
}

/* Individual chat message */
.message {
    margin-bottom: 15px; /* Increased margin */
    padding: 12px 18px; /* Increased padding */
    border-radius: 18px; /* Increased border radius */
    max-width: 75%; /* Slightly increased width */
    word-wrap: break-word;
    animation: fadeIn 0.3s ease-in-out; /* Fade-in animation */
}

/* User message styling */
.message.user {
    background-color: #4a90e2; /* More vibrant blue for user messages */
    color: white;
    align-self: flex-end;
    animation: slideInRight 0.3s ease-in-out; /* Slide-in animation for user messages */
}

/* Support message styling */
.message.support {
    background-color: #e6eaf1; /* Softer gray for support messages */
    color: #333;
    align-self: flex-start;
    animation: slideInLeft 0.3s ease-in-out; /* Slide-in animation for support messages */
}

/* Chat input area */
.chat-input {
    display: flex;
    align-items: center;
    padding: 15px; /* Increased padding */
    background-color: #f1f3f6;
    border-top: 1px solid #ccc;
}

/* Chat input field */
.chat-input input {
    flex: 1;
    margin-right: 10px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 20px; /* Rounded corners */
    font-size: 16px; /* Increased font size */
}

/* Send button */
.chat-input button {
    padding: 12px 20px; /* Increased padding */
    border: none;
    background-color: #4a90e2; /* Matching button color with user message */
    color: white;
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px; /* Increased font size */
}

/* Send button hover effect */
.chat-input button:hover {
    background-color: #357ab8; /* Darker blue on hover */
}

/* Keyframes for animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideInRight {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

@keyframes slideInLeft {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}
