/* dashboard.css */

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center vertically */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background: linear-gradient(135deg, #000000, #3b3b5b); /* Rainbow gradient background */
    color: #fff;
    font-family: Arial, sans-serif;
    overflow: hidden; /* Prevents horizontal scroll */
}

.dashboard-content {
    width: 80%;
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #1c1c1c;
    border-radius: 8px;
    text-align: center; /* Center text horizontally */
}

h1 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    color: #ffffff; /* Light blue text */
}

.boxes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #2a2a2a;
    color: #f8f8f8; /* Light blue text */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.box:hover {
    background-color: #89acbd; /* Darker blue on hover */
    transform: translateY(-5px);
}

.popup {
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the popup */
    width: 80%;
    max-width: 600px; /* Limit maximum width */
    max-height: 80%; /* Limit maximum height */
    background-color: rgba(0, 0, 0, 0.8); /* semi-transparent black background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow-y: auto; /* Enable vertical scrolling if needed */
}

.popup-content {
    text-align: center;
}

.popup h2 {
    font-size: 24px;
    color: #fff;
}

.popup p {
    font-size: 16px;
    color: #ccc;
}

.popup button {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.popup button:hover {
    background-color: #0056b3;
}
