/* RegistrationForm.css */

.registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registration-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.registration-form {
    width: 100%;
}

.registration-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.registration-form input,
.registration-form textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.registration-form textarea {
    resize: vertical;
    min-height: 100px;
}

.registration-form button {
    width: 100%;
    padding: 12px;
    border: none;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.registration-form button:hover {
    background-color: #0056b3;
}

.message {
    margin-top: 16px;
    padding: 12px;
    background-color: #dff0d8; /* Bootstrap success alert background color */
    border: 1px solid #c3e6cb; /* Bootstrap success alert border color */
    color: #155724; /* Bootstrap success alert text color */
    border-radius: 4px;
    text-align: center;
}
