.about-container {
    top: 0;
    left: 20vw; /* Initial left position */
    position: absolute;
    width: calc(80vw - 200px); /* Adjust width to accommodate sidebar, initial 80vw */
    padding: 20px;
    height: auto;
    overflow-y: auto; /* Enable vertical scrolling if content exceeds viewport */
    transition: width 0.3s ease; /* Add transition for smooth resizing */
}

.sidebar {
    top: 8vh;
    left: 0;
    width: 200px;
    height: 100vh;
    position: fixed;
    background-color: #2a2828;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease; /* Add transition for smooth resizing */
}

.sidebar.active {
    width: 250px; /* Adjusted width when sidebar is active or expanded */
}

.section {
    margin-top: 20vh;
    padding: 20px 0;
    justify-content: center;
    transition: margin-left 0.3s ease; /* Add transition for smooth margin change */
}

.sidebar a {
    display: block;
    margin: 10px 0;
    text-decoration: none;
    color: #ffffff;
}

.sidebar a:hover {
    color: #007bff;
}

.document-container h1 {
    top: 100px;
    position: absolute;
}

.code_example {
    width: 70vw;   /* The container is 30% of the viewport width */
    height: auto;  /* The height adjusts automatically to maintain the aspect ratio */
}

.code_example img {
    width: 100%;   /* The image takes up 100% of the container's width */
    height: auto;  /* The height adjusts automatically to maintain the aspect ratio */
    display: block; /* Removes the space below the image */
}
