/* src/About.css */

.about-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 110vh;
    width: 100vw;
    margin: 0; /* Reset margin */
    padding: 0; /* Reset padding */
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    overflow: auto;
    background: rgb(66, 85, 164) 98%;
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

/* src/index.css */

body {
    background-color: black;
    color: white; /* Change text color to white for better contrast */
    margin: 0;
    font-family: Arial, sans-serif;
}

/* Ensure other elements are styled accordingly */
h1, h2, p {
    color: white;
}


p {
    line-height: 1.6;
}


section h2 {
    margin-bottom: 10px;
}
